import { FC, useMemo } from "react";
import {
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  XAxis,
  YAxis,
} from "recharts";
import { SailorResult } from "./types";

export const ResultHistory: FC<{ results: SailorResult[] }> = ({ results }) => {
  let data = useMemo(
    () =>
      results
        .map((r) => {
          const pos =
            r.position == null
              ? 1
              : (r.position - 1) / (r.total_participants - 1);
          return {
            pos,
            year: new Date(r.date).getFullYear(),
          };
        })
        .reverse()
        .map((pos, n) => ({ ...pos, n })),
    [results]
  );
  if (data.length < 2) {
    return null;
  }

  const ticks = data.reduce(
    (acc, d, n) =>
      acc.prev_year === null || acc.prev_year === d.year
        ? {
            ticks: acc.ticks,
            prev_year: d.year,
          }
        : {
            ticks: [...acc.ticks, { n, year: d.year }],
            prev_year: d.year,
          },
    {
      ticks: [],
      prev_year: null,
    } as { ticks: Array<{ n: number; year: number }>; prev_year: number | null }
  ).ticks;

  const regressions = sliceArray(data, Math.round(data.length / 8)).map(
    (group) => ({
      ...linearRegression(group.map((v) => ({ x: v.n, y: v.pos }))),
      x0: group[0].n,
      xf: group.at(-1)!.n,
    })
  );
  const midPoints = getMidpoints(regressions);

  return (
    <div className="result-history">
      <h3>Històric de resultats</h3>
      <ResponsiveContainer width="100%" height={200}>
        <ScatterChart margin={{ top: 8, bottom: 5, left: 5, right: 5 }}>
          <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
          <XAxis
            dataKey="n"
            type="number"
            name="data"
            tick={ticks.length > 0 ? undefined : false}
            ticks={ticks.map(({ n }) => n)}
            tickFormatter={(v) =>
              ticks.find(({ n }) => n === v)?.year.toString() ?? ""
            }
            stroke="white"
            domain={["dataMin", "dataMax"]}
          />
          <YAxis
            dataKey="pos"
            type="number"
            name="posicio"
            ticks={[0, 0, 1]}
            domain={[0, 1]}
            tickFormatter={(value) => (value === 0 ? "Primer" : "Últim")}
            reversed
            stroke="white"
          />
          {ticks.map(({ n }) => (
            <ReferenceLine key={n} x={n} />
          ))}
          <Scatter name="historic" data={data} fill="#231c3d" />
          <Scatter
            line
            name="regression"
            data={midPoints}
            fill="#231c3d"
            shape={<></>}
          />
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  );
};

function sliceArray<T>(arr: T[], size: number): T[][] {
  const result: T[][] = [];

  if (size <= 1) return [arr];

  const remainder = arr.length % size;
  if (remainder > 0) {
    result.push(arr.slice(0, remainder));
  }

  for (let i = remainder; i < arr.length; i += size) {
    result.push(arr.slice(i, i + size));
  }

  return result;
}

function linearRegression(data: { x: number; y: number }[]) {
  const sx = data.reduce((acc, v) => acc + v.x, 0);
  const sy = data.reduce((acc, v) => acc + v.y, 0);
  const sxy = data.reduce((acc, v) => acc + v.x * v.y, 0);
  const sx2 = data.reduce((acc, v) => acc + v.x * v.x, 0);
  // const sy2 = data.reduce((acc, v) => acc + v.y * v.y, 0);
  const n = data.length;

  if (n === 0) {
    return { a: 0, b: 0 };
  }
  if (n === 1) {
    return {
      a: 0,
      b: data[0].y,
    };
  }

  const d = n * sx2 - sx * sx;

  const a = (n * sxy - sx * sy) / d;
  const b = (sy * sx2 - sx * sxy) / d;

  // Y = aX + b
  return { a, b };
}

function getMidpoints(
  regressions: { a: number; b: number; x0: number; xf: number }[]
) {
  const result: Array<{ n: number; pos: number }> = [];
  result.push({
    n: regressions[0].x0,
    pos: regressions[0].a * regressions[0].x0 + regressions[0].b,
  });
  for (let i = 1; i < regressions.length; i++) {
    const prevY =
      regressions[i - 1].a * regressions[i - 1].xf + regressions[i - 1].b;
    const currY = regressions[i].a * regressions[i].x0 + regressions[i].b;
    result.push({
      n: regressions[i].x0,
      pos: (prevY + currY) / 2,
    });
  }
  result.push({
    n: regressions.at(-1)!.xf,
    pos: regressions.at(-1)!.a * regressions.at(-1)!.xf + regressions.at(-1)!.b,
  });
  return result;
}
