import { useEffect, useState } from "react";
import { getApiURL } from "../config";

export type GetAPIResult<T> = {
  value: T | null;
  loading: boolean;
  // Per saber si el `value` es el que correspon. Pot ser que per un moment no coincideixi.
  loadedPath: string | null;
};

/**
 * Fa la crida a la API i torna el valor
 *
 * @param path
 */
export function useGetFromAPI<T>(path: string | null) {
  const [result, setResult] = useState<GetAPIResult<T>>({
    value: null,
    loading: true,
    loadedPath: null,
  });

  // Sempre que el path canvii..
  useEffect(() => {
    // Si ens han passat `null`, vol dir que encara no podem enviar la request
    if (!path) {
      setResult({
        value: null,
        loading: false,
        loadedPath: null,
      });
      return;
    }

    // Posem loading a true.
    setResult((prev) => ({
      ...prev,
      loading: true,
    }));

    // Necesitem un AbortController per si volem cancelar la petició.
    const abortController = new AbortController();
    fetch(getApiURL(path), {
      signal: abortController.signal,
    })
      .then((res) => res.json())
      .then(
        (value) => {
          // OnSuccess, guardem el valor rebut desde l'API
          setResult({
            value,
            loading: false,
            loadedPath: path,
          });
        },
        (error) => {
          // En error el deixem a null, pero amb loading a false.
          // Potser haurem de pensar millor com gestionar els errors. Sentry?
          console.error(error);
          setResult({
            value: null,
            loading: false,
            loadedPath: null,
          });
        }
      );

    return () => abortController.abort();
  }, [path]);

  return result;
}
