import { Link } from "react-router-dom";
import { HomeConfig } from "./types";
import "./HomePage.css";

interface Props {
  item: HomeConfig;
}

const TileHomePage = ({ item }: Props) => {
  return (
    <Link
      to={`/${item.type}/${item.id}`}
      className={`item-homepage ${item.type === "regata" ? "regata" : "group"}`}
    >
      <p>{item.name}</p>
    </Link>
  );
};

export default TileHomePage;
