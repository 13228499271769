import React from 'react'
import EventCalendar from './EventCalendar'
import { EventCalendarType } from '../../types'

interface Props {
  events: EventCalendarType[]
}

const DisplayEvents = ({ events }: Props) => {

  return (
    <div className='display-events-container'>
      {
        events.map(event => {
          return (
              <EventCalendar event={event} />
          )
        })
      }
    </div>
  )
}

export default DisplayEvents