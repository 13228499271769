import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getApiURL } from "../../config";

export const RegisterWithPassword: FC<{ sailorId: number }> = ({
  sailorId,
}) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState("");

  const navigate = useNavigate();

  const isValid =
    username.length > 0 && password.length >= 8 && password === passwordConfirm;

  const error = (() => {
    if (serverError) {
      return serverError;
    }
    if (password.length && password.length < 8) {
      return "La contrassenya ha de tindre almenys 8 caràcters";
    }
    if (password !== passwordConfirm) {
      return "Les contrassenyes no coincideixen";
    }
  })();

  const onSubmit = async () => {
    setIsLoading(true);

    try {
      const result = await fetch(getApiURL("/registre.php"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "registerPassword",
          username,
          password,
          sailor_id: sailorId,
        }),
      });

      const response = await result.json();
      if (response.error) {
        setServerError(response.error);
      } else {
        window.localStorage.setItem("appati-token", response.token);
        navigate("/");
      }
    } catch (ex) {
      console.error(ex);
      setServerError(
        "No t'has pogut registrar degut a un error desconegut. Contacta amb algun administrador per ajudar-te"
      );
    }
    setIsLoading(false);
  };

  return (
    <div className="signin-content">
      <input
        type="text"
        placeholder="Nom d'usuari"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        type="password"
        placeholder="Contrassenya"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <input
        type="password"
        placeholder="Confirma contrassenya"
        value={passwordConfirm}
        onChange={(e) => setPasswordConfirm(e.target.value)}
      />
      {error && <p>{error}</p>}
      <button disabled={!isValid || isLoading} onClick={onSubmit}>
        Registra't
      </button>
    </div>
  );
};
