const Holfuy = () => {
  return (
    <div className="content">
      <iframe
        className="holfuy-vertical-frame"
        title="holfuy-rose"
        width="100%"
        height="100%"
        src="https://widget.holfuy.com/?station=1201&su=knots&t=C&lang=es&mode=vertical&responsive"
      ></iframe>
      <iframe
        className="holfuy-average-frame"
        title="holfuy-average"
        width="100%"
        height="170"
        src="https://widget.holfuy.com/?station=1201&su=knots&t=C&lang=es&mode=average&avgrows=32"
      ></iframe>
    </div>
  );
};

export default Holfuy;
