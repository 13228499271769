import { isCampionat } from "./features";

export function getBaseApiURL() {
  return isCampionat
    ? "https://appati.pativelabarcelona.com/campCat/_api"
    : process.env.REACT_APP_API_URL!;
}
export function getApiURL(path: string) {
  return `${getBaseApiURL()}${path}`;
}
