import avatar from "../../imgs/avatar.png";

interface Props {
  sailor_prof: any;
}

const ProfileInfo = ({ sailor_prof }: Props) => {
  return (
    <div className="profile">
      <img className="avatar-profile" src={avatar} alt="user-profile-pic" />
      <h1 className="name-profile">{sailor_prof?.name}</h1>
      <h2 className="boat-sail-profile">
        <span className="name-boat-profile">{sailor_prof?.boat_name}</span>{" "}
        <span className="sail-number-profile">{sailor_prof?.sail_number}</span>
      </h2>
    </div>
  );
};

export default ProfileInfo;
