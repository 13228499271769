import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getApiURL } from "../../config";

export const LoginWithPassword = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState("");

  const navigate = useNavigate();

  const isValid = username.length > 0 && password.length > 0;

  const onSubmit = async () => {
    setIsLoading(true);

    try {
      const result = await fetch(getApiURL("/registre.php"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "loginPassword",
          username,
          password,
        }),
      });

      const response = await result.json();
      setIsLoading(false);
      if (response.error) {
        setServerError(response.error);
      } else {
        window.localStorage.setItem("appati-token", response.token);
        navigate("/");
      }
    } catch (ex) {
      console.error(ex);
      setIsLoading(false);
      setServerError(
        "No t'has pogut registrar degut a un error desconegut. Contacta amb algun administrador per ajudar-te"
      );
    }
  };

  return (
    <div className="signin-content">
      <input
        type="text"
        placeholder="Nom d'usuari"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        type="password"
        placeholder="Contrassenya"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      {serverError && <p>{serverError}</p>}
      <button disabled={!isValid || isLoading} onClick={onSubmit}>
        Entra
      </button>
    </div>
  );
};
