import "./App.css";
import Topbar from "./components/Topbar";
import HomePage from "./components/homePage/HomePage";
import Regata from "./components/regata/Regata";
import { Routes, Route } from "react-router-dom";
import BottomBar from "./components/BottomBar";
import Cerca from "./components/cerca/Cerca";
import { MyPerfil, OtherPerfil } from "./components/perfil/Perfil";
import { Ranking } from "./components/ranking/Ranking";
import Estela from "./components/links/Estela";
import Links from "./components/links/Links";
import Windy from "./components/links/Windy";
import Holfuy from "./components/links/Holfuy";
import CalendarApp from "./components/calendar/CalendarApp";
import Toa from "./components/Toa/Toa";
import { Signin } from "./components/signin/Signin";
import { RegataEstela } from "./components/regata/RegataEstela";
import { Webcams } from "./components/links/Webcams";

function App() {
  return (
    <div className="App">
      <Topbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/desktop" element={<HomePage />} />
        <Route path="/regata/:id/:race?" element={<Regata />} />
        <Route path="/regata/:id/:race/estela" element={<RegataEstela />} />
        <Route path="/group/:id" element={<Ranking />} />
        <Route path="/cerca" element={<Cerca />} />
        <Route path="/links" element={<Links />} />
        <Route path="/estela" element={<Estela />} />
        <Route path="/holfuy" element={<Holfuy />} />
        <Route path="/windy" element={<Windy />} />
        <Route path="/webcams" element={<Webcams />} />
        <Route path="/calendar/:day?" element={<CalendarApp />} />
        <Route path="/perfil/:sailorId" element={<OtherPerfil />} />
        <Route path="/perfil" element={<MyPerfil />} />
        <Route path="/toa" element={<Toa />} />
        <Route path="/signin/*" element={<Signin />} />
      </Routes>
      <BottomBar />
    </div>
  );
}

export default App;
