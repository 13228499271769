const Estela = () => {
  return (
    <div className="content">
      <iframe
        title="estela"
        width="100%"
        height="100%"
        src="https://www.estela.co/es/tracking-club/1784/club-pati-vela-barcelona"
        allowFullScreen
      ></iframe>
    </div>
  )
}

export default Estela;
