import { Sailor } from '../../types'
import DisplaySailors from './DisplaySailor'

interface Props {
    sailors: Sailor[],
    onSailorClick?: (s: Sailor) => void
}

const SailorsDisplay = ({ sailors, onSailorClick }: Props) => {
    return (
        <table className='sailors-display'>
            {
                sailors.map(sailorToDisplay => {
                    return (
                        <tbody>
                            <DisplaySailors sailorToDisp={sailorToDisplay} onSailorClick={onSailorClick} />
                        </tbody>
                    )
                })
            }
        </table>
    )
} 

export default SailorsDisplay