import React, { useState } from "react";
import Calendar from "react-calendar";
import { useCachedAPI } from "../../hooks/useCachedAPI";
import "./CalendarApp.css";
import DisplayEvents from "./DisplayEvents";
import { EventCalendarType } from "../../types";
import dayjs from "dayjs";
import TileEvent from "./TileEvent";
import LlistaEntreno from "./LlistaEntreno";
import { showEntrenos } from "../../features";

const CalendarApp = () => {
  const [value, onChange] = useState(new Date());

  const date = dayjs(value.toString()).format("YYYY-MM-DD");
  const response = useCachedAPI<EventCalendarType[]>("/eventCalendar.php");

  if (!response.value && response.loading) {
    return null; // TODO posar un spiner xupiguay
  }
  if (!response.value) {
    return null; // TODO posar que no es poden carregar les dades. Ha passat algun error
  }

  const eventsAll = response.value;
  const dates: string[] = [];
  const events: EventCalendarType[] = [];
  // potser volem posar un parametre a eventCalendar.php per que només carregui els events del mes per optimitzar, ara carrega tots els que hi ha
  for (let index = 0; index < eventsAll.length; index++) {
    if (eventsAll[index].date === date) events.push(eventsAll[index]);
    dates.push(eventsAll[index].date);
  }

  return (
    <div className="calendar-content">
      <div className="calendar-cal-entreno">
        <div className="calendar-calendar">
          <Calendar
            onChange={onChange}
            value={value}
            tileContent={({ date }) => {
              let month: string = (date.getMonth() + 1).toString();
              if (Number(month) < 10) month = "0" + month;
              let day: string = date.getDate().toString();
              if (Number(day) < 10) day = "0" + day;
              let realDate: string =
                date.getFullYear() + "-" + month + "-" + day;
              if (dates.find((val) => val === realDate)) return <TileEvent />;
              return null;
            }}
            prev2Label={null}
            next2Label={null}
          />
        </div>
        <div className="entreno-events">
          {showEntrenos() ? (
            <LlistaEntreno day={value} llistaPart={["llista", "llista"]} />
          ) : null}
          <DisplayEvents events={events} />
        </div>
      </div>
    </div>
  );
};

export default CalendarApp;
