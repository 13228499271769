import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { SailorResults } from "../../types";
import Classificat from "./Classificat";

interface Props {
  name: string;
  sailors: SailorResults[];
  race: { number: number; date: Date; estela?: string };
}

const ClassifRace = ({ name, sailors, race }: Props) => {
  const { id } = useParams();

  const sortedSailors = useMemo(
    () =>
      [...sailors]
        .filter((sailor) => sailor.results[race.number].position !== "DNC")
        .sort(
          (a, b) =>
            a.results[race.number].points - b.results[race.number].points
        ),
    [sailors, race.number]
  );

  return (
    <>
      <div className="header-regata">
        <h1 className="titol-regata">{name}</h1>
        <div className="botons">
          {race.estela ? <Link to="estela">eStela</Link> : null}
          <Link to={"/regata/" + id}>General</Link>
        </div>
      </div>
      <div className="classif-container">
        <table className="classif">
          <tbody>
            {sortedSailors.map((sailor_classificat) => {
              return (
                <Classificat
                  key={sailor_classificat.id}
                  sailorClassif={sailor_classificat}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ClassifRace;
