import { Link } from "react-router-dom";
import "./Links.css";
import estela from "./estela.png";
import holfuy from "./holfuy.png";
import windy from "./windy.png";
import skyline from "./skyline.png";
import { isCampionat } from "../../features";

const Links = () => {
  return (
    <div className="content">
      <div className="grid-container">
        {!isCampionat && (
          <Link to={"/holfuy"} className="grid-item">
            <img src={holfuy} alt="logo holfuy" />
            <p>Holfuy</p>
          </Link>
        )}
        <Link to={"/windy"} className="grid-item">
          <img src={windy} alt="logo windy" />
          <p>Windy</p>
        </Link>
        {!isCampionat && (
          <Link to={"/estela"} className="grid-item">
            <img
              src={estela}
              alt="logo estela"
              style={{ borderRadius: "5px" }}
            />
            <p>Estela</p>
          </Link>
        )}
        <Link to={"/webcams"} className="grid-item">
          <img src={skyline} alt="logo skyline" />
          <p>Webcams</p>
        </Link>
      </div>
    </div>
  );
};

export default Links;
