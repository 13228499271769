import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { v4 } from "uuid";
import { getApiURL } from "./config";

function getVisitorId() {
  if (!localStorage.getItem("visitor_id")) {
    localStorage.setItem("visitor_id", v4());
  }
  return localStorage.getItem("visitor_id")!;
}

async function registerVisit(path: string) {
  try {
    const result = await fetch(getApiURL("/analytics.php"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        action: "page_view",
        visitor_id: getVisitorId(),
        path,
      }),
    });

    await result.json();
  } catch (ex) {
    console.error(ex);
    // No ens importa si hi ha hagut un error. Com a molt ho pujariem a Sentry per poder rebre notificacions que les analitiques no funcionen
  }
}

export const Analytics = () => {
  const location = useLocation();

  useEffect(() => {
    registerVisit(location.pathname);
  }, [location.pathname]);

  return null;
};
