import { Theme } from "@glideapps/glide-data-grid";
import "@glideapps/glide-data-grid/dist/index.css";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCachedAPI } from "../../hooks/useCachedAPI";
import { Sailor } from "../../types";
import { ResizableGrid, ResizableGridColumn } from "../ResizableGrid";
import "./Ranking.css";

type RankingResult = Sailor & {
  score: number;
  results: Record<
    number, // regata_id
    | Record<
        number, // race_num
        | {
            points: number;
            ranking_points: number;
          }
        | undefined
      >
    | undefined
  >;
};

interface RankingResponse {
  regates: Record<
    number,
    {
      id: number;
      name: string;
      races: Array<string>; // dates
    }
  >;
  results: Array<RankingResult>;
}

export const Ranking = () => {
  const { id } = useParams();
  const ranking = useCachedAPI<RankingResponse>(`/ranking.php?id=${id}`);

  const sailors = useMemo(() => ranking.value?.results ?? [], [ranking.value]);

  const maxScore = useMemo(
    () => sailors.map((s) => s.score).reduce((a, b) => Math.max(a, b), 0) ?? 0,
    [sailors]
  );
  const minScore = useMemo(
    () =>
      sailors
        .map((s) => s.score)
        .reduce((a, b) => Math.min(a, b), Number.POSITIVE_INFINITY) ?? 0,
    [sailors]
  );
  const scoreDiff = maxScore - minScore;

  const totalRaces = ranking.value?.regates
    ? Object.values(ranking.value?.regates)
        .map((r) => r.races.length)
        .reduce((a, b) => a + b, 0)
    : 0;

  const navigate = useNavigate();
  const columns: ResizableGridColumn<RankingResult>[] = useMemo(() => {
    const baseColumns: ResizableGridColumn<RankingResult>[] = [
      {
        id: "pos",
        title: "Pos",
        getCellContent: (_, i) => i + 1 + ".",
        onCellClick: (row) => navigate("/perfil/" + row.id),
      },
      {
        id: "name",
        title: "Nom",
        getCellContent: (row) =>
          row.name ?? row.boat_name ?? row.sail_number ?? "",
        width: (scale) => Math.round(100 * scale),
      },
      {
        id: "category",
        title: "Ct",
        getCellContent: (row) => ({
          displayData: row.category || "?",
          data: row.category,
          themeOverride:
            row.category === "1a"
              ? {
                  textDark: "white",
                  bgCell: `hsl(0deg 0% 20%)`,
                }
              : {
                  textDark: "black",
                  bgCell: `hsl(0deg 0% 80%)`,
                },
          contentAlign: "center",
        }),
      },
      {
        id: "score",
        title: "Punts",
        getCellContent: (row) => ({
          displayData: row.score.toString(),
          data: row.score.toString(),
          themeOverride: {
            textDark: "white",
            bgCell: `hsl(0deg 80% ${
              (50 * (row.score - minScore)) / scoreDiff
            }%)`,
          },
          contentAlign: "right",
        }),
      },
      {
        id: "participacio",
        title: "Part.",
        getCellContent: (row) => {
          const text = Object.values(row.results)
            .flatMap((r) => (r ? Object.values(r).length : 0))
            .reduce((a, b) => a + b, 0)
            .toString();
          return {
            data: text,
            displayData: text,
            contentAlign: "right",
          };
        },
      },
      {
        id: "percentParticipacio",
        title: "Part.%",
        getCellContent: (row) => {
          const participacio = Object.values(row.results)
            .flatMap((r) => (r ? Object.values(r).length : 0))
            .reduce((a, b) => a + b, 0);
          const text = Math.round((participacio / totalRaces) * 100) + "%";
          return {
            data: text,
            displayData: text,
            contentAlign: "right",
          };
        },
      },
      {
        id: "medals",
        title: "Victories",
        getCellContent: (row) => {
          const medals = Object.values(row.results)
            .flatMap((r) =>
              r ? Object.values(r).map((v) => v?.points ?? null) : []
            )
            .filter((points) => points != null && points <= 3);

          if (!medals.length) return "";

          const medalCount = medals.reduce(
            (acc, pos) => {
              const newCounts = [...acc];
              newCounts[pos! - 1]++;

              return newCounts;
            },
            [0, 0, 0]
          );
          return medalCount
            .map((count, pos) =>
              count > 0 ? `${count} ${positionToMedal[pos]}` : ""
            )
            .filter((v) => v !== "")
            .join(" ");
        },
      },
    ];

    const regates = Object.values(ranking.value?.regates ?? {});
    const regatesColumns = regates.flatMap(
      (regata, i): ResizableGridColumn<RankingResult>[] => {
        const themeOverride: Partial<Theme> = {
          bgCell: `hsl(${Math.round((360 * i) / 3)}deg 50% 94%)`,
        };
        const totalColumn: ResizableGridColumn<RankingResult> = {
          id: `${regata.name}-separator`,
          title: regata.name,
          getCellContent: () => "",
          themeOverride,
          onHeaderClick: () => navigate("/regata/" + regata.id),
        };

        const racesColumns = regata.races.map(
          (race, i): ResizableGridColumn<RankingResult> => {
            const date = new Date(race);

            return {
              id: `${regata.name}-${i}`,
              title: date.getDate() + "/" + (date.getMonth() + 1),
              onHeaderClick: () => navigate("/regata/" + regata.id + "/" + i),
              getCellContent: (row) => {
                const result = row.results[regata.id] ?? [];
                const points = result[i]?.ranking_points ?? 0;
                const medalIndex = (result[i]?.points ?? 0) - 1;
                const medal = positionToMedal[medalIndex] ?? "";
                const medalColor = positionToMedalColor[medalIndex] ?? "";

                const text = points > 0 ? medal + points.toString() : "";

                if (!medal) {
                  return {
                    data: text,
                    displayData: text,
                    contentAlign: "right",
                  };
                }

                return {
                  data: text,
                  themeOverride: {
                    textDark: medalColor,
                  },
                  displayData: text,
                  bold: true,
                  textShadow: true,
                  contentAlign: "right",
                };
              },
              themeOverride,
              alignHeaderRight: true,
            };
          }
        );

        return [totalColumn, ...racesColumns];
      }
    );
    return [...baseColumns, ...regatesColumns];
  }, [ranking, navigate, minScore, scoreDiff, totalRaces]);

  // Per calcular la mitjana de participants, podem comptar quantes putuacions estàn i dividir pel nombre de regates
  const totalScores = sailors
    .flatMap((sailor) =>
      Object.values(sailor.results).map((resultsProva) =>
        resultsProva ? Object.values(resultsProva).length : 0
      )
    )
    .reduce((a, b) => a + b, 0);
  const participantAverage = totalScores / totalRaces;
  const participantAverageString = totalRaces
    ? Math.round(participantAverage).toString()
    : "N/A";

  /**
   * To patch:
   * Add maxWidth to column properties, apply changes
   */
  return (
    <>
      <div className="header-ranking">
        <h1>Ranking</h1>
        <div className="regata-info">
          <div>Proves: {totalRaces}</div>
          <div>Part. Mitja: {participantAverageString}</div>
        </div>
      </div>
      <ResizableGrid
        className="classif-ranking"
        columns={columns}
        data={sailors}
        freezeColumns={2}
        pinchBounds={{
          min: 0.4,
          max: 4 / 3,
        }}
      />
    </>
  );
};

const positionToMedalColor = ["gold", "silver", "#cc8748"];

const positionToMedal = ["🥇", "🥈", "🥉"];
