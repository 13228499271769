import './Cerca.css';
import { useMemo, useState } from 'react';
import { Sailor } from '../../types';
import SailorsSearch from './SailorsDisplay';
import {useNavigate} from 'react-router-dom'
import { useCachedAPI } from '../../hooks/useCachedAPI';

export const Cerca = ({ onSailorClick }: {onSailorClick: (sailor: Sailor) => void}) => {
    const taulaUsuaris = useCachedAPI<Sailor[]>("/patinaires.php");
    const [busqueda, setBusqueda] = useState("");

    const handleChange = (e: { target: { value: string; }; }) => {
        setBusqueda(e.target.value);
    }

    const usuaris = useMemo(() => {
        if (!taulaUsuaris.value) return [];

        return taulaUsuaris.value.filter((element: Sailor)=>{
            if(insensitiveTransform(element.name)?.includes(insensitiveTransform(busqueda))
            || insensitiveTransform(element.boat_name)?.includes(insensitiveTransform(busqueda))
            || insensitiveTransform(element.sail_number?.toString())?.includes(insensitiveTransform(busqueda))){
                return true;
            }
            return false;
        });
    }, [busqueda, taulaUsuaris.value]);

    return (
        <div className='content content-cerca'>
            <div className="container-input">
                <input className='input-field' value={busqueda} placeholder="Busca un patinaire" onChange={handleChange} />
            </div>
            <div className="container-sailors">
                <SailorsSearch sailors={usuaris} onSailorClick={onSailorClick} />
            </div>
        </div>
    )
}

export default function CercaPage () {
    const naviagate = useNavigate();
    return <Cerca onSailorClick={sailor => naviagate(`/perfil/${sailor.id}`)} />
}

function insensitiveTransform<T extends string | undefined>(str: T): T {
    // https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript/37511463#37511463
    return str?.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLocaleLowerCase() as T;
}
