import React, { useState } from 'react'
import { EventCalendarType } from '../../types'
import dayjs from 'dayjs'

interface Props {
  event: EventCalendarType
}

const EventCalendar = ({ event }: Props) => {

  const [show, setShow] = useState(false)

  return (
    <button type='button' onClick={() => setShow(!show)}>
      <div key={event.id} className='event-container'>
        <p><b>{event.title}</b> | {dayjs(event.date.toString()).format('DD/MM')}</p>
        {show &&
          <div>
            <p>{event.text}</p>
            {/* {event.llistaPart && <LlistaPart llistaPart={} />} */}
          </div>}
      </div>
    </button>
  )
}

export default EventCalendar


