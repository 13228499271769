// from https://stackoverflow.com/questions/21797299/convert-base64-string-to-arraybuffer
export function base64ToArrayBuffer(base64: string) {
  // PHP utilitza la variant RFC4648 de base64
  // It's actually part of the standard https://github.com/web-auth/webauthn-framework/issues/285#issuecomment-1250329513
  base64 = base64.replaceAll("-", "+").replaceAll("_", "/");
  var dataUrl = "data:application/octet-binary;base64," + base64;

  return fetch(dataUrl).then((res) => res.arrayBuffer());
}

export function arrayBufferToBase64(buffer: ArrayBuffer) {
  const blob = new Blob([buffer], { type: "application/octet-binary" });
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = function (evt) {
      const dataurl = evt.target!.result as string;
      // Convertim-ho tambe al RFC4648
      const base64 = dataurl
        .substring(dataurl.indexOf(",") + 1)
        .replaceAll("+", "-")
        .replaceAll("/", "_")
        .replaceAll("=", "");
      resolve(base64);
    };
    reader.readAsDataURL(blob);
  });
}
