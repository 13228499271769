const Windy = () => {
  return (
    <div className="content">
      <iframe
        title="windy"
        width="100%"
        height="100%"
        src="https://embed.windy.com/embed2.html?lat=41.336&lon=2.126&detailLat=41.387&detailLon=2.170&width=650&height=450&zoom=8&level=surface&overlay=wind&product=ecmwf&menu=&message=&marker=true&calendar=now&pressure=&type=map&location=coordinates&detail=true&metricWind=default&metricTemp=default&radarRange=-1"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default Windy;
