import { useEffect, useState } from "react";
import { Route, Routes } from "react-router";
import { Link } from "react-router-dom";
import { Sailor } from "../../types";
import { LoginWithFingerprint } from "./LoginWithFingerprint";
import { LoginWithPassword } from "./LoginWithPassword";
import { RegisterWithFingerprint } from "./RegisterWithFingerprint";
import { RegisterWithPassword } from "./RegisterWithPassword";
import { SailorPicker } from "./SailorPicker";
import "./Signin.css";

export const Signin = () => {
  const indexView = (
    <div className="signin-index">
      <p>Necesites estar registrat per poder accedir</p>
      <div className="links">
        <Link to="/signin/login">Login</Link>
        <Link to="/signin/register">Registre</Link>
      </div>
    </div>
  );

  return (
    <Routes>
      <Route index element={indexView} />
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
    </Routes>
  );
};

const Login = () => {
  const [typeSelected, setTypeSelected] = useState<"fingerprint" | "password">(
    "fingerprint"
  );
  const supportsFingerprint = useSupportsFingerprint();

  const renderContent = () => {
    if (supportsFingerprint === null) return null;
    if (supportsFingerprint) {
      return (
        <div>
          <p>Selecciona si vas entrar amb emprempta o amb contrassenya</p>
          <div className="option-group">
            <label>
              <input
                type="radio"
                name="type"
                checked={typeSelected === "fingerprint"}
                onChange={() => setTypeSelected("fingerprint")}
              />
              <span>Emprempta</span>
            </label>
            <label>
              <input
                type="radio"
                name="type"
                checked={typeSelected === "password"}
                onChange={() => setTypeSelected("password")}
              />
              <span>Contrassenya</span>
            </label>
          </div>
          {typeSelected === "fingerprint" ? (
            <LoginWithFingerprint />
          ) : (
            <LoginWithPassword />
          )}
        </div>
      );
    }

    return <LoginWithPassword />;
  };

  return (
    <div className="signin-register">
      <h2>Login</h2>
      {renderContent()}
    </div>
  );
};

const Register = () => {
  const [selectedSailor, setSelectedSailor] = useState<Sailor | null>(null);
  const [typeSelected, setTypeSelected] = useState<"fingerprint" | "password">(
    "fingerprint"
  );
  const supportsFingerprint = useSupportsFingerprint();

  const renderContent = () => {
    if (!selectedSailor) return null;
    if (selectedSailor.username) {
      return (
        <div>
          <p className="warning">
            Algu amb l'usuari "{selectedSailor.username}" ja s'ha registrat amb
            aquest patinaire.
          </p>
          <p>
            Si ja et vas registrar, fes{" "}
            <Link className="link" to="../login">
              Login
            </Link>
          </p>
          <p>
            Si el patinaire que has seleccionat ets tu i vols recuperar-lo,
            demana-ho als administradors i l'alliberaràn perque el puguis agafar
          </p>
        </div>
      );
    }

    if (supportsFingerprint) {
      return (
        <div>
          <p>
            Pots registrar-te utilitzant contrassenya, o si el teu dispositiu ho
            té, emprempta digital
          </p>
          <div className="option-group">
            <label>
              <input
                type="radio"
                name="type"
                checked={typeSelected === "fingerprint"}
                onChange={() => setTypeSelected("fingerprint")}
              />
              <span>Emprempta</span>
            </label>
            <label>
              <input
                type="radio"
                name="type"
                checked={typeSelected === "password"}
                onChange={() => setTypeSelected("password")}
              />
              <span>Contrassenya</span>
            </label>
          </div>
          {typeSelected === "fingerprint" ? (
            <RegisterWithFingerprint sailorId={selectedSailor.id} />
          ) : (
            <RegisterWithPassword sailorId={selectedSailor.id} />
          )}
        </div>
      );
    }

    return <RegisterWithPassword sailorId={selectedSailor.id} />;
  };

  return (
    <div className="signin-register">
      <h2>Registre</h2>
      <p>
        Necesites ser un patinaire per poder-te registrar. Busca'n un i
        selecciona'l
      </p>
      <SailorPicker sailor={selectedSailor} onChange={setSelectedSailor} />
      {renderContent()}
    </div>
  );
};

const supportsFingerprintPromise = (async () => {
  if (!window.PublicKeyCredential) return false;

  return window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
})();

const useSupportsFingerprint = () => {
  return usePromise(supportsFingerprintPromise);
};

const usePromise = <T extends any>(promise: Promise<T>): T | null => {
  const [value, setValue] = useState<T | null>(null);

  useEffect(() => {
    promise.then((r) => setValue(r));
  }, [promise]);

  return value;
};
