import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { arrayBufferToBase64, base64ToArrayBuffer } from "./encoding";
import jwt_decode from "jwt-decode";
import { getApiURL } from "../../config";

export const LoginWithFingerprint = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState("");
  const navigate = useNavigate();

  const submit = async () => {
    setIsLoading(true);
    try {
      const response = await webauthnLogin();
      if (response.error) {
        setServerError(response.error);
      } else {
        window.localStorage.setItem("appati-token", response.token);
        navigate("/");
      }
    } catch (ex) {
      console.error(ex);
      setServerError(
        "No has pogut entrar degut a un error desconegut. Contacta amb algun administrador per ajudar-te"
      );
    }
    setIsLoading(false);
  };

  return (
    <div className="signin-content">
      {serverError && <p>{serverError}</p>}
      <button disabled={isLoading} onClick={submit}>
        Entra
      </button>
    </div>
  );
};

async function webauthnLogin() {
  const initateResult = await fetch(
    getApiURL("/registre.php"),
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        action: "initiateWebauthnLogin",
      }),
    }
  ).then((res) => res.json());

  if (initateResult.error) {
    return initateResult;
  }

  const credentialOptions: any = jwt_decode(initateResult.credentialOptions);

  const data = (await navigator.credentials.get({
    publicKey: {
      ...credentialOptions,
      challenge: await base64ToArrayBuffer(credentialOptions.challenge),
      allowCredentials: credentialOptions.allowCredentials
        ? await Promise.all(
            credentialOptions.allowCredentials.map(async (v: any) => ({
              ...v,
              id: await base64ToArrayBuffer(v.id),
            }))
          )
        : undefined,
    },
  })) as PublicKeyCredential | null;

  if (!data) {
    throw new Error("no credential");
  }

  const response = await fetch(
    getApiURL("/registre.php"),
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        action: "loginWebauthn",
        data: {
          // Can't pass data directly because it wouldn't get serialized
          id: data.id,
          rawId: await arrayBufferToBase64(data.rawId),
          response: {
            clientDataJSON: await arrayBufferToBase64(
              data.response.clientDataJSON
            ),
            authenticatorData: await arrayBufferToBase64(
              (data.response as AuthenticatorAssertionResponse)
                .authenticatorData
            ),
            signature: await arrayBufferToBase64(
              (data.response as AuthenticatorAssertionResponse).signature
            ),
            userHandle: (data.response as AuthenticatorAssertionResponse)
              .userHandle
              ? await arrayBufferToBase64(
                  (data.response as AuthenticatorAssertionResponse).userHandle!
                )
              : null,
          },
          type: data.type,
        },
        credentialOptions: initateResult.credentialOptions,
      }),
    }
  ).then((res) => res.json());

  return response;
}
