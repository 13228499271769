import { FC, useMemo } from "react";
import { Link } from "react-router-dom";
import { ResultHeatmap } from "./ResultHeatmap";
import { ResultHistory } from "./ResultHistory";
import { SailorResult } from "./types";

export const ProfileStats: FC<{ results: SailorResult[] }> = ({ results }) => {
  return (
    <div className="profile-stats">
      <ResultHeatmap results={results} />
      <ResultHistory results={results} />
    </div>
  );
};

export const LastTrimester: FC<{ results: SailorResult[] }> = ({ results }) => {
  const lastTrimesterResult = useMemo(() => {
    const now = new Date();
    // Aproximadament, coi. Sino haurem de fer servir `date-fns`
    const monthAgo = new Date(now.getTime() - 1000 * 60 * 60 * 24 * 30 * 3);

    const firstOutside = results.findIndex(
      (result) => new Date(result.date).getTime() < monthAgo.getTime()
    );
    if (firstOutside < 0) {
      // Si no el trobem, vol dir que tots estan dins del ultim mes
      return results;
    }

    return results.slice(0, firstOutside);
  }, [results]);

  const medals = useMemo(
    () =>
      lastTrimesterResult.filter((r) => r.position != null && r.position <= 3),
    [lastTrimesterResult]
  );
  const medalCount = useMemo(() => {
    // Comptem les medalles amb un reduce.
    return medals.reduce(
      (acc, result) => {
        const newCounts = [...acc];
        newCounts[result.position! - 1]++;

        return newCounts;
      },
      [0, 0, 0]
    );
  }, [medals]);

  const bestResults = useMemo(() => {
    const resultsWithPosition = lastTrimesterResult.filter(
      (res) => res.position !== null
    );

    if (resultsWithPosition.length === 0) return null;

    const absolute = resultsWithPosition.reduce((min, result) =>
      min.position! > result.position! ? result : min
    );
    const relative = resultsWithPosition.reduce((min, result) =>
      getRelativePosition(min)! > getRelativePosition(result)! ? result : min
    );
    return { absolute, relative };
  }, [lastTrimesterResult]);

  return (
    <div className="profile-stats last-trimester">
      {medals.length ? (
        <div className="section">
          <h3 className="section-title">🏅 Últimes posicions amb podi</h3>
          <div className="medal-count">
            {medalCount.map((count, m) => (
              <div key={m}>
                {count}
                {positionToMedal[m]}
              </div>
            ))}
          </div>
          <div className="medals">
            {medals.map((result) => (
              <Medal key={result.date} result={result} />
            ))}
          </div>
        </div>
      ) : (
        <div className="section">
          <h3 className="section-title">🎉 Millors posicions recents</h3>
          {bestResults ? (
            <>
              <div className="best-position">
                <div className="best-position-title">
                  Millor posició absoluta
                </div>
                <Link
                  to={`/regata/${bestResults.absolute.race_regata_id}/${bestResults.absolute.race_number}`}
                  className="best-name"
                >
                  {formatDate(bestResults.absolute.date)}{" "}
                  {bestResults.absolute.name}
                </Link>
                {bestResults.absolute.position}ª posició de{" "}
                {bestResults.absolute.total_participants}
              </div>
              <div className="best-position">
                <div className="best-position-title">
                  Millor posició relativa
                </div>
                <Link
                  to={`/regata/${bestResults.relative.race_regata_id}/${bestResults.relative.race_number}`}
                  className="best-name"
                >
                  {formatDate(bestResults.relative.date)}{" "}
                  {bestResults.relative.name}
                </Link>
                Top{" "}
                {Math.round(getRelativePosition(bestResults.relative)! * 100)}%,{" "}
                {bestResults.relative.position}ª de{" "}
                {bestResults.relative.total_participants}
              </div>
            </>
          ) : (
            <div className="no-position">
              No té cap resultat durant l'últim trimestre
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const Medal: FC<{ result: SailorResult }> = ({ result }) => {
  if (!result.position || result.position > 3) return null;

  return (
    <Link
      to={`/regata/${result.race_regata_id}/${result.race_number}`}
      className="medal"
    >
      {positionToMedal[result.position - 1]} {formatDate(result.date)}{" "}
      {result.name}
    </Link>
  );
};

const positionToMedal = ["🥇", "🥈", "🥉"];

function getRelativePosition(result: SailorResult) {
  if (result.position === null) return null;
  return (result.position - 1) / (result.total_participants - 1);
}

function formatDate(dateString: string) {
  const date = new Date(dateString);
  // getMonth() torna 0 = gener
  return date.getDate() + "/" + (date.getMonth() + 1);
}
