import { state } from "@react-rxjs/core";
import {
  defer,
  filter,
  map,
  Observable,
  scan,
  startWith,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from "rxjs";
import { fromFetch } from "rxjs/fetch";
import { getApiURL } from "../config";
import { createSignal } from "@react-rxjs/utils";

const VERSION = process.env.REACT_APP_GIT_HASH ?? "dev";

export interface Announcement {
  id: string;
  name: string;
  path: string;
  time: string;
}
export const toa$ = state(
  fromFetch(getApiURL("/toa.php")).pipe(
    switchMap((v) => v.json() as Promise<Array<Announcement>>),
    cacheStream("/toa.php")
  ),
  null
);

export const [view$, viewToa] = createSignal();
const viewed$ = view$.pipe(
  withLatestFrom(toa$),
  map(([, toa]) => toa?.map((v) => v.path) ?? []),
  cacheStream("viewed-toa", "1")
);

export const alertBadge$ = state(
  viewed$.pipe(
    startWith([] as string[]),
    switchMap((viewed) =>
      toa$.pipe(
        filter((v) => v !== null),
        take(1),
        map((toa) => toa!.filter((v) => !viewed.includes(v.path)).length)
      )
    )
  ),
  0
);

export const [read$, readToa] = createSignal<string>();
export const readToa$ = state(
  defer(() => {
    const savedValue = localStorage.getItem("read-toa");
    const initialValue = new Set<string>(
      savedValue ? JSON.parse(savedValue) : []
    );

    return read$.pipe(
      scan((acc, v) => {
        acc.add(v);
        return acc;
      }, initialValue),
      startWith(initialValue),
      map((v) => Array.from(v)),
      tap((v) => localStorage.setItem("read-toa", JSON.stringify(v)))
    );
  }),
  []
);

function cacheStream<T>(key: string, version = VERSION) {
  return (source$: Observable<T>) =>
    defer((): Observable<T> => {
      const cachedValue: { version: string; value: T } | null = (() => {
        try {
          return JSON.parse(localStorage.getItem(key)!);
        } catch (ex) {
          return null;
        }
      })();
      const value =
        cachedValue && cachedValue?.version === version!
          ? cachedValue.value
          : null;

      return source$.pipe(
        tap((value) =>
          localStorage.setItem(
            key,
            JSON.stringify({
              version,
              value,
            })
          )
        ),
        value != null ? startWith(value) : (v) => v
      );
    });
}
