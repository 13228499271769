import "./BottomBar.css";
import { Link } from "react-router-dom";
import { AiOutlineHome } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import { MdLink } from "react-icons/md";
import { BsCalendar4 } from "react-icons/bs";
import { GrAnnounce } from "react-icons/gr";
import { isCampionat, showToa } from "../features";
import { useStateObservable } from "@react-rxjs/core";
import { alertBadge$ } from "../state/toa.state";

const BottomBar = () => {
  return (
    <div className="bottom-bar">
      <Link className="tab" to="/">
        <div className="icon">
          <AiOutlineHome size={25} />
        </div>
      </Link>
      {!isCampionat && (
        <Link className="tab" to="/cerca">
          <div className="icon">
            <BiSearch size={25} />
          </div>
        </Link>
      )}
      <Link className="tab" to="/links">
        <div className="icon">
          <MdLink size={25} />
        </div>
      </Link>
      {!isCampionat && (
        <Link className="tab" to="/calendar">
          <div className="icon">
            <BsCalendar4 size={25} />
          </div>
        </Link>
      )}
      {showToa() ? <ToaButton /> : null}
    </div>
  );
};

const ToaButton = () => {
  const badge = useStateObservable(alertBadge$);

  return (
    <Link className="tab" to="/toa">
      <div className="icon relative">
        <GrAnnounce size={25} />
        {badge > 0 && (
          <div className="bg-red-600 text-white text-xs px-1 rounded-full absolute -right-1 -top-2">
            {badge}
          </div>
        )}
      </div>
    </Link>
  );
};

export default BottomBar;
