import { getApiURL } from "../../config";

export const Webcams = () => {
  // Per forçar que carregui una nova imatge cada 5 minuts com a minim
  const t = Math.floor(Date.now() / (5 * 60 * 1000));

  return (
    <div className="webcams">
      <div className="webcam-group">
        <h3>Barcelona.cat</h3>
        <img
          alt="webcam"
          src={getApiURL("/bcnWebcam.php?num=2&t=" + t)}
        />
        <img
          alt="webcam"
          src={getApiURL("/bcnWebcam.php?num=1&t=" + t)}
        />
      </div>
      <div className="webcam-group">
        <h3>Skyline webcams</h3>
        <a
          href="https://www.skylinewebcams.com/es/webcam/espana/cataluna/barcelona/playa-sant-sebastia.html"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={"https://embed.skylinewebcams.com/img/492.jpg?t=" + t}
            alt="【LIVE】 Playa de Sant Sebastià - Barcelona | SkylineWebcams"
          />
        </a>
        <a
          href="https://www.skylinewebcams.com/es/webcam/espana/cataluna/barcelona/playa-barceloneta.html"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={"https://embed.skylinewebcams.com/img/496.jpg?t=" + t}
            alt="【LIVE】 Barcelona - Barceloneta | SkylineWebcams"
          />
        </a>
      </div>
      <div className="webcam-group">
        <h3>CCMA</h3>
        <iframe
          title="tv3"
          width="100%"
          height="280"
          src="https://www.ccma.cat/el-temps/embed/barcelona-centre/cameraembed/1/?rel=0&amp;showinfo=0"
        ></iframe>
      </div>
    </div>
  );
};
