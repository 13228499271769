import { FC } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useGetFromAPI } from "../../hooks/useGetFromAPI";
import { useAuth } from "../../useAuth";
import { GeneralHistoryCharts } from "./GeneralHistory";
import "./Perfil.css";
import ProfileInfo from "./ProfileInfo";
import { LastTrimester, ProfileStats } from "./ProfileStats";
import { SailorResponse } from "./types";

export const MyPerfil = () => {
  const auth = useAuth();

  const sailorResponse = useGetFromAPI<SailorResponse>(
    auth ? "/patinaires.php?user_id=" + auth.user_id : null
  );

  if (!auth) {
    return <Navigate to="/signin" replace />;
  }

  if (!sailorResponse.value) {
    return null; // TODO Mostrar loading
  }

  return <Perfil response={sailorResponse.value} />;
};

export const OtherPerfil = () => {
  const { sailorId } = useParams();
  const sailorResponse = useGetFromAPI<SailorResponse>(
    "/patinaires.php?id=" + sailorId
  );

  if (!sailorResponse.value) {
    return null; // TODO Mostrar loading
  }

  return <Perfil response={sailorResponse.value} />;
};

const Perfil: FC<{ response: SailorResponse; resetProfile?: () => void }> = ({
  response: { sailor, resultats, general_history: generalHistory },
}) => {
  return (
    <div className="content profile-content">
      <ProfileInfo sailor_prof={sailor} />
      <LastTrimester results={resultats} />
      <GeneralHistoryCharts generalHistory={generalHistory} />
      <ProfileStats results={resultats} />
    </div>
  );
};
