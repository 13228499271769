import "./Topbar.css";
import cpvbimg from "../imgs/cpvbVermell.png";
import cnbimg from "../imgs/cnb.png";
import avatar from "../imgs/avatar.png";
import { Link } from "react-router-dom";
import { isCampionat, showUser } from "../features";

const Topbar = () => {
  return (
    <nav className="top-bar">
      <img alt="cpvb" className="cpvb" src={cpvbimg} />
      {isCampionat ? <img alt="cnb" src={cnbimg} /> : null}
      {showUser() ? (
        <Link to={"/perfil"}>
          <img alt="avatar" className="avatar" src={avatar} />
        </Link>
      ) : null}
    </nav>
  );
};

export default Topbar;
