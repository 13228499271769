import { MdOutlineArrowBack } from "react-icons/md";
import { Link, Navigate, useParams } from "react-router-dom";
import { useCachedAPI } from "../../hooks/useCachedAPI";
import { ResultResponse } from "./Regata";
import "./Regata.css";

export const RegataEstela = () => {
  const { id, race: raceNum } = useParams();
  const response = useCachedAPI<ResultResponse>("/results.php?regata_id=" + id);

  if (!response.value) {
    return null;
  }

  const race = raceNum ? response.value.regata.races[Number(raceNum)] : null;

  if (!race || !race.estela) {
    return <Navigate to="/" />;
  }

  return (
    <div className="content">
      <Link to="./.." className="floating-back">
        <MdOutlineArrowBack size={25} />
      </Link>
      <iframe
        title="estela"
        width="100%"
        height="100%"
        src={race.estela}
        allowFullScreen
      ></iframe>
    </div>
  );
};
