import { FC, useEffect, useMemo, useState } from "react";
import { useCachedAPI } from "../../hooks/useCachedAPI";
import { Sailor } from "../../types";
import "./SailorPicker.css";

export const SailorPicker: FC<{
  sailor: Sailor | null;
  onChange: (id: Sailor) => void;
}> = ({ sailor: selectedSailor, onChange }) => {
  const sailors = useCachedAPI<Sailor[]>("/patinaires.php");
  const [search, setSearch] = useState("");
  const [hasFocus, setFocus] = useState(false);

  const filteredSailors = useMemo(() => {
    if (search.trim() === "") return [];
    if (!sailors.value) return [];

    return sailors.value
      .filter((s) => {
        if (
          insensitiveTransform(getSailorText(s))?.includes(
            insensitiveTransform(search)
          )
        ) {
          return true;
        }
        return false;
      })
      .slice(0, 20); // N'agafem un maxim de 20 per evitar que React hagi de carregar molts elements (mobils antics)
  }, [sailors, search]);

  const dropdown = (
    <ul className="sailor-dropdown">
      {filteredSailors.map((sailor) => (
        <li key={sailor.id} onClick={() => onChange(sailor)}>
          <span className="sailor-name">{sailor.name}</span>
          {sailor.boat_name ? (
            <span className="sailor-boat">{sailor.boat_name}</span>
          ) : null}
          {sailor.sail_number ? (
            <span className="sailor-number">{sailor.sail_number}</span>
          ) : null}
        </li>
      ))}
    </ul>
  );

  useEffect(() => {
    if (!hasFocus) {
      // Hem de fer-ho asíncron perque sino el dropdown es tanca abans de que es pugui fer click
      setTimeout(() => {
        setSearch("");
      }, 200);
    }
  }, [hasFocus]);

  return (
    <div className="sailor-picker">
      <input
        type="type"
        placeholder="Buscar per nom, barco o vela..."
        value={
          hasFocus
            ? search
            : selectedSailor
            ? getSailorText(selectedSailor)
            : ""
        }
        onChange={(e) => setSearch(e.target.value)}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
      {filteredSailors.length ? dropdown : null}
    </div>
  );
};

function getSailorText(sailor: Sailor) {
  return [sailor.name, sailor.boat_name, sailor.sail_number]
    .filter((v) => v && v !== "")
    .join(", ");
}

function insensitiveTransform<T extends string | undefined>(str: T): T {
  // https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript/37511463#37511463
  return str
    ?.normalize("NFD")
    .replace(/\p{Diacritic}/gu, "")
    .toLocaleLowerCase() as T;
}
