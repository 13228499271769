import {
  CellClickedEventArgs,
  CompactSelection,
  DataEditor,
  DrawCustomCellCallback,
  GridCell,
  GridCellKind,
  GridColumn,
  HeaderClickedEventArgs,
  Item,
  drawTextCell,
  getMiddleCenterBias,
} from "@glideapps/glide-data-grid";
import "@glideapps/glide-data-grid/dist/index.css";
import { addBreadcrumb } from "@sentry/react";
import { PinchBounds, usePinch } from "@use-gesture/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export type ResizableGridColumn<T> = Omit<GridColumn, "width"> & {
  getCellContent(
    row: T,
    index: number
  ):
    | string
    | (Partial<GridCell> & {
        bold?: boolean;
        textShadow?: boolean;
      });
  width?: number | ((scale: number) => number);
  drawCell?: DrawCustomCellCallback;
  onHeaderClick?: (event: HeaderClickedEventArgs) => void;
  onCellClick?: (row: T, event: CellClickedEventArgs) => void;
  alignHeaderRight?: boolean;
};

export interface ResizableGridProps<T> {
  data: T[];
  columns: ResizableGridColumn<T>[];
  className?: string;
  freezeColumns?: number;
  pinchBounds?: PinchBounds;
}

export function ResizableGrid<T>({
  data,
  columns,
  freezeColumns,
  pinchBounds,
  className,
}: ResizableGridProps<T>) {
  // Controla com de gran o petita es fa el grid
  const [scale, setScale] = useState(1);
  // <div> de fora on s'observaran el pinch-to-zoom
  const ref = useRef<HTMLDivElement | null>(null);
  /*
    Una mica hack, pero el DataGrid detecta un "click" quan l'usuari acaba de fer pinch-to-zoom,
    la qual cosa es fa molt frustrant. Necesitem una forma de poder excloure els events quan s'està
    fent el pinch-to-zoom, aquesta variable ho guarda.
  */
  const isDoingGesture = useRef(false);
  // Hem de deshabilitar el pinch-zoom natiu del navegador.
  useDisablePinchZoom();
  // Ens torna una funcio per poder fer canvis al mateix FPS del navegador
  const raf = useAnimationFrame();
  // Part del hack isDoingGesture, necesitem que al cap d'un delay, es cridi una funció que el posi a false
  const debounceCall = useDebounceCallback();
  // Aquest hook ens detecta el pinch gesture.
  usePinch(
    (state) => {
      const scale = state.offset[0];
      isDoingGesture.current = true;
      raf(() => {
        setScale(scale);
        debounceCall(() => {
          isDoingGesture.current = false;
        });
      });
    },
    {
      pointer: {
        touch: true,
      },
      scaleBounds: pinchBounds,
      target: ref.current ?? undefined,
    }
  );

  // El grid mante una cache de column.id => width. Si canviem l'scale, hem de dir-li que es una altre columna.
  const mappedColumns = useMemo(
    () =>
      columns.map((col) => ({
        ...col,
        width:
          col.width === undefined
            ? undefined
            : typeof col.width === "number"
            ? col.width
            : col.width(scale),
        id: col.id !== undefined ? col.id + "__" + scale : (undefined as any),
      })),
    [columns, scale]
  );

  useEffect(() => {
    addBreadcrumb({
      message: "data init or changed",
      data: {
        newLength: data.length,
      },
    });
  }, [data]);

  const getCellContent = useCallback(
    ([c, r]: Item) => {
      const row = data[r];
      if (!row) {
        // captureMessage("Row data is undefined!", {
        //   extra: {
        //     row,
        //     rowNumber: r,
        //     dataLength: data.length,
        //     data: data.slice(Math.max(0, r - 5), r + 5),
        //   },
        // });
        return {
          allowOverlay: false,
          kind: GridCellKind.Text,
          displayData: "???",
          data: "???",
        };
      }
      const column = columns[c];
      const content = column.getCellContent(row, r);

      if (typeof content !== "string") {
        const c: any = content;
        if (!c.kind || c.kind === GridCellKind.Text) {
          c.data = c.data ?? "";
          c.displayData = c.displayData ?? "";
        }
        return {
          allowOverlay: false,
          kind: GridCellKind.Text,
          displayData: "",
          data: "",
          ...content,
        } as any;
      }
      return {
        allowOverlay: false,
        kind: GridCellKind.Text,
        displayData: content ?? "",
        data: content ?? "",
      };
    },
    [columns, data]
  );

  const [selectedRow, setSelectedRow] = useState<number | null>(null);

  return (
    <div ref={ref} className={className}>
      <DataEditor
        theme={{
          baseFontStyle: Math.round(12 * scale) + "px",
          headerFontStyle: Math.round(12 * scale) + "px",
          cellVerticalPadding: 6 * scale,
          cellHorizontalPadding: 6 * scale,
          accentLight: "#4264",
          bgIconHeader: "#231c3d",
          bgHeader: "white",
        }}
        verticalBorder={false}
        rowHeight={24 * scale}
        headerHeight={Math.round(18 * scale)}
        minColumnWidth={12 * scale}
        getCellContent={getCellContent}
        columns={mappedColumns}
        rows={data.length}
        smoothScrollX
        smoothScrollY
        getCellsForSelection // Es necessari per poder fer que les columnes agafin un width automatic (no se perque...?)
        freezeColumns={freezeColumns}
        drawCell={(args) => {
          const column = columns[args.col];
          if (column.drawCell) {
            return column.drawCell(args);
          }
          const a = args as any;
          /**
           * Com que baseFontStyle també té el fontSize a més del "bold", hem de deixar que el consumidor
           * ens passi si vol en negreta fer-ho nosaltres per no complicarli la vida.
           * De pas li oferim poder afegir un textShadow :)
           */
          if (a.cell.bold || a.cell.textShadow) {
            const fontSize = Number.parseFloat(args.theme.baseFontStyle);

            args.ctx.save();
            if (a.cell.bold) {
              args.ctx.font = `bold ${args.theme.baseFontStyle} ${args.theme.fontFamily}`;
            }
            if (a.cell.textShadow) {
              args.ctx.save();
              args.ctx.strokeStyle = "black";
              args.ctx.lineWidth = (2 * fontSize) / 16;
              args.ctx.lineJoin = "round";
              args.ctx.miterLimit = 2;
              const original = args.ctx.fillText;
              args.ctx.fillText = args.ctx.strokeText;
              drawTextCell(a, a.cell.displayData, a.cell.contentAlign);
              args.ctx.fillText = original;
              args.ctx.restore();
            }
            drawTextCell(a, a.cell.displayData, a.cell.contentAlign);
            args.ctx.restore();
            return true;
          }
          return false;
        }}
        drawHeader={({ column, rect, ctx, isSelected, theme }) => {
          if (!(column as ResizableGridColumn<T>).alignHeaderRight) {
            return false;
          }

          const fillStyle = isSelected
            ? theme.textHeaderSelected
            : theme.textHeader;
          ctx.fillStyle = fillStyle;
          const textMetrics = ctx.measureText(column.title);
          ctx.fillText(
            column.title,
            rect.x +
              Math.max(
                0,
                rect.width - textMetrics.width - theme.cellHorizontalPadding
              ),
            rect.y +
              rect.height / 2 +
              getMiddleCenterBias(
                ctx,
                `${theme.headerFontStyle} ${theme.fontFamily}`
              )
          );

          return true;
        }}
        onHeaderClicked={(c, evt) => {
          if (!isDoingGesture.current) {
            columns[c].onHeaderClick?.(evt);
          }
        }}
        onCellClicked={([c, r], event) => {
          if (!isDoingGesture.current) {
            columns[c].onCellClick?.(data[r], event);
          }
        }}
        getRowThemeOverride={(row) =>
          row % 2 === 0
            ? {
                bgCell: "hsl(180deg 40% 96%)",
              }
            : {}
        }
        //  : row % 3 === 1 ? {
        //   bgCell: 'hsl(300deg 40% 96%)'
        // } : {
        //   bgCell: 'hsl(60deg 40% 96%)'
        // }
        rangeSelect="cell" // Deshabilita la seleccio multiple, que també es fot d'osties amb el pinch-to-zoom
        gridSelection={{
          columns: CompactSelection.empty(),
          rows:
            selectedRow !== null
              ? CompactSelection.fromSingleSelection(selectedRow)
              : CompactSelection.empty(),
        }}
        onGridSelectionChange={({ current }) => {
          if (!current?.cell) return;
          const [c, r] = current.cell;

          if (freezeColumns && c < freezeColumns) {
            setSelectedRow(r);
          }
        }}
      />
    </div>
  );
}

function useDisablePinchZoom() {
  useEffect(() => {
    const preventDefault = (e: Event) => e.preventDefault();
    const onTouchStart = (evt: TouchEvent) => {
      if (evt.touches.length > 1) {
        // disable pinch gesture
        evt.preventDefault();
      }
    };
    document.body.addEventListener("touchstart", onTouchStart, {
      passive: false,
    });
    document.addEventListener("gesturestart", preventDefault);
    document.addEventListener("gesturechange", preventDefault);

    return () => {
      document.body.removeEventListener("touchstart", onTouchStart);
      document.removeEventListener("gesturestart", preventDefault);
      document.removeEventListener("gesturechange", preventDefault);
    };
  }, []);
}

/**
 * Torna una funció que executarà la última callback que se li hagi passat
 * quan el navegador estigui llest per pintar.
 */
function useAnimationFrame() {
  const lastRafCb = useRef<(() => void) | null>(null);
  const rafToken = useRef<number | null>(null);

  useEffect(
    () => () => {
      if (rafToken.current) {
        cancelAnimationFrame(rafToken.current);
        rafToken.current = null;
        lastRafCb.current = null;
      }
    },
    []
  );

  return (cb: () => void) => {
    lastRafCb.current = cb;
    if (rafToken.current == null) {
      rafToken.current = requestAnimationFrame(() => {
        lastRafCb.current?.();
        lastRafCb.current = null;
        rafToken.current = null;
      });
    }
  };
}

/**
 * Torna una funció que executarà la última callback que se li hagi passat
 * quan es deixi d'spamejar
 */
function useDebounceCallback(timeMs = 200) {
  const debounceToken = useRef<NodeJS.Timeout | null>(null);

  useEffect(
    () => () => {
      if (debounceToken.current) {
        clearTimeout(debounceToken.current);
        debounceToken.current = null;
      }
    },
    []
  );

  return (cb: () => void) => {
    if (debounceToken.current) {
      clearTimeout(debounceToken.current);
    }
    debounceToken.current = setTimeout(() => {
      cb();
      debounceToken.current = null;
    });
  };
}
