import { SailorResults } from "../../types";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { isCampionat } from "../../features";

interface Props {
  sailorClassif: SailorResults;
}

const Classificat = ({ sailorClassif }: Props) => {
  const { race } = useParams();

  const result = sailorClassif.results[Number(race)];
  const isPenalty = Number.isNaN(Number(result.position));

  return (
    <tr className="classificat">
      <td className={isPenalty ? "position penalty" : "position"}>
        {isCampionat ? (
          <span>{result.position}.</span>
        ) : (
          <Link to={"/perfil/" + sailorClassif.id}>{result.position}.</Link>
        )}
      </td>
      <td className="name">
        {sailorClassif.name} |{" "}
        <span className="boatName">{sailorClassif.boat_name}</span> |{" "}
        <span className="sail_number">{sailorClassif.sail_number}</span>
      </td>
      <td>{result.points}</td>
    </tr>
  );
};

export default Classificat;
