import { useParams } from "react-router-dom";
import { useCachedAPI } from "../../hooks/useCachedAPI";
import { SailorResults } from "../../types";
import { ClassifGeneral } from "./ClassifGeneral";
import ClassifRace from "./ClassifRace";
import "./Regata.css";

export interface ResultResponse {
  regata: {
    name: string;
    races: Array<{
      number: number;
      date: string; // YYYY-MM-DD
      estela?: string;
    }>;
  };
  results: SailorResults[];
}

const Regata = () => {
  const { id, race: raceNum } = useParams();
  const response = useCachedAPI<ResultResponse>("/results.php?regata_id=" + id);

  if (!response.value) {
    return null;
  }

  const name = response.value.regata.name;
  const sailors = response.value.results;
  const races = response.value.regata.races.map((race) => ({
    ...race,
    date: new Date(race.date),
  }));
  const race = raceNum ? races[Number(raceNum)] : null;

  return (
    <div className="content regata-content">
      {race ? (
        <ClassifRace name={name} sailors={sailors} race={race} />
      ) : (
        <ClassifGeneral name={name} sailors={sailors} races={races} />
      )}
    </div>
  );
};

export default Regata;
