import ReactDOM from "react-dom/client";
import App from "./App";
import "core-js/stable/array/at";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { setupServiceWorker } from "./ServiceWorker";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Analytics } from "./Analytics";
import { ErrorBoundary, initSentry } from "./ErrorManagement";
import { polyfill } from "./polyfills";
import { isCampionat } from "./features";

initSentry();
polyfill();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter basename={isCampionat ? "campCat" : ""}>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
    <ToastContainer />
    <Analytics />
  </BrowserRouter>
);

setupServiceWorker();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
