import jwtDecode from "jwt-decode";

export interface Token {
  user_id: string;
  issued_at: number;
}

export function useAuth() {
  const token = window.localStorage.getItem("appati-token");

  return token ? jwtDecode<Token>(token) : null;
}
