import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

export function setupServiceWorker() {
  /**
   * El service worker emet quan s'ha instalat per primera vegada (onSuccess)
   * o quan hi ha una actualització disponible (onUpdate).
   *
   * Es una bona pràctica utilitzar aquests events per informar-li al usuari
   * de que s'ha instalat / o que hi ha una nova versió.
   */
  serviceWorkerRegistration.register({
    onSuccess() {
      // Si s'ha instalat per primera vegada li diem al usuari que pot utilitzar-la offline.
      // toast("Aquesta app ja es pot utilitzar offline!");
    },
    onUpdate(registration) {
      // Si hi ha una actualització li diem que pot actualitzar.
      // Per defecte, l'app només s'actualitza si l'usuari tanca totes les tabs que tingui obertes amb aquesta aplicació.
      // Aqui li dono l'opció de forçar la instalació i recarregar l'app.
      const performUpdate = () => {
        registration.waiting?.postMessage({
          type: "SKIP_WAITING",
        });
      };

      const id = toast(() => (
        <div className="service-worker-toast" onClick={performUpdate}>
          <div>
            Hi ha una nova actualització de la app, per tant es reiniciarà per
            actualitzar-se, o prem aqui per per reiniciarla ara.
          </div>
        </div>
      ));
      toast.onChange((evt) => {
        if (evt.id === id && evt.status === "removed") {
          performUpdate();
        }
      });
    },
    onMessage(evt) {
      if (evt.data?.type === "SW_UPDATED") {
        window.location.reload();
      }
    },
  });
}
