import jwt_decode from "jwt-decode";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getApiURL } from "../../config";
import { arrayBufferToBase64, base64ToArrayBuffer } from "./encoding";

export const RegisterWithFingerprint: FC<{ sailorId: number }> = ({
  sailorId,
}) => {
  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState("");
  const navigate = useNavigate();

  const isValid = username.length > 0;

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const response = await webauthnRegister(username, sailorId);
      if (response.error) {
        setServerError(response.error);
      } else {
        window.localStorage.setItem("appati-token", response.token);
        navigate("/");
      }
    } catch (ex) {
      console.error(ex);
      setServerError(
        "No t'has pogut registrar degut a un error desconegut. Contacta amb algun administrador per ajudar-te"
      );
    }
    setIsLoading(false);
  };

  return (
    <div className="signin-content">
      <input
        type="text"
        placeholder="Nom d'usuari"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      {serverError && <p>{serverError}</p>}
      <button disabled={!isValid || isLoading} onClick={onSubmit}>
        Registra't
      </button>
    </div>
  );
};

async function webauthnRegister(username: string, sailorId: number) {
  const initateResult = await fetch(getApiURL("/registre.php"), {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      action: "initiateWebauthn",
      username,
      sailor_id: sailorId,
    }),
  }).then((res) => res.json());

  if (initateResult.error) {
    return initateResult;
  }

  const credentialOptions = jwt_decode<any>(initateResult.credentialOptions);

  const data = (await navigator.credentials.create({
    publicKey: {
      ...credentialOptions,
      challenge: await base64ToArrayBuffer(credentialOptions.challenge),
      user: {
        ...credentialOptions.user,
        id: await base64ToArrayBuffer(credentialOptions.user.id),
      },
    },
  })) as PublicKeyCredential | null;

  if (!data) {
    throw new Error("no credential");
  }

  const response = await fetch(getApiURL("/registre.php"), {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      action: "registerWebauthn",
      data: {
        // Can't pass data directly because it wouldn't get serialized
        authenticatorAttachment: data.authenticatorAttachment,
        id: data.id,
        rawId: await arrayBufferToBase64(data.rawId),
        response: {
          attestationObject: await arrayBufferToBase64(
            (data.response as AuthenticatorAttestationResponse)
              .attestationObject
          ),
          clientDataJSON: await arrayBufferToBase64(
            data.response.clientDataJSON
          ),
        },
        type: data.type,
      },
      sailor_id: sailorId,
      credentialOptions: initateResult.credentialOptions,
    }),
  }).then((res) => res.json());

  return response;
}
