import { Sailor } from "../../types";

interface Props {
  sailorToDisp: Sailor;
  onSailorClick?: (s: Sailor) => void;
}

const DisplaySailor = ({ sailorToDisp, onSailorClick }: Props) => {
  return (
    <tr className="sailor-disp">
      <td key={sailorToDisp.id} onClick={() => onSailorClick?.(sailorToDisp)}>
        <span className="name">{sailorToDisp.name}</span> <span className="bar-name">|</span>{" "}
        <span className="boatName">{sailorToDisp.boat_name}</span> <span className="bar-name">|</span>{" "}
        <span className="sail_number">{sailorToDisp.sail_number}</span>
      </td>
    </tr>
  );
};

export default DisplaySailor;
