import { FC } from "react";
import { Link } from "react-router-dom";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { GeneralHistory } from "./types";

export const GeneralHistoryCharts: FC<{
  generalHistory: GeneralHistory[];
}> = ({ generalHistory }) => {
  const relevantHistories = generalHistory.filter(
    ({ positions }) => positions.length > 0
  );
  if (!relevantHistories.length) return null;

  return (
    <div className="profile-stats">
      <h3>Posicions Generals</h3>
      {relevantHistories.map((history) => (
        <GeneralHistoryRace key={history.id} history={history} />
      ))}
    </div>
  );
};

const GeneralHistoryRace: FC<{ history: GeneralHistory }> = ({ history }) => {
  const data = history.positions.map((pos, n) => ({
    n: n + 1,
    pos,
    general: history.incremental_results[n],
  }));

  const lastPosition = history.incremental_results.at(-1)!;

  const ticks = [
    1,
    1,
    5,
    10,
    20,
    30,
    40,
    50,
    60,
    70,
    80,
    90,
    100,
    history.sailors + 1,
  ].filter((t) => t <= history.sailors + 1);

  const canDrawChart = data.length > 1;

  const chart = (
    <ResponsiveContainer width="100%" height={200}>
      <LineChart data={data} margin={{ top: 5, bottom: 5, left: 5, right: 15 }}>
        <CartesianGrid stroke="#ccc" />
        <XAxis dataKey="n" stroke="white" />
        <YAxis
          reversed
          domain={[1, history.sailors + 1]}
          ticks={ticks}
          width={30}
          stroke="white"
        />
        <Line
          animationDuration={0}
          dataKey="pos"
          stroke="none"
          fill="#231c3d"
        />
        <Line
          animationDuration={0}
          type="linear"
          dataKey="general"
          stroke="#231c3d"
          dot={false}
        />
        {/* <Line name="general" data={general} stroke="red"  /> */}
      </LineChart>
    </ResponsiveContainer>
  );

  return (
    <div className="general-chart">
      <div className="general-title">
        <Link to={`/regata/${history.id}`}>
          {history.name}: {lastPosition}ª posició
        </Link>
      </div>
      {canDrawChart ? chart : null}
    </div>
  );
};
