import dayjs from "dayjs";
import { FC, useMemo } from "react";
import { SailorResult } from "./types";
import "./ResultHeatmap.css";

export const ResultHeatmap: FC<{ results: SailorResult[] }> = ({ results }) => {
  const data = useMemo(() => {
    const now = dayjs();
    const start = dayjs().subtract(1, "year").set("date", 1).set("hour", 0);

    // Weight serà pel color, ja que depèn de com es degraden una mica.
    // Quantity es el valor original de quantes regates cauen a aquell tier.
    const result: Record<
      string,
      {
        month: string;
        tiers: Array<{ weight: number; quantity: number }>;
        total: number;
      }
    > = {};
    for (let day = dayjs(start); day.isBefore(now); day = day.add(1, "month")) {
      result[getMonthKey(day)] = {
        month: getMonthLabel(day),
        tiers: new Array(10).fill(0).map(() => ({ weight: 0, quantity: 0 })),
        total: 0,
      };
    }

    const relevantResults = results.filter((r) => {
      // Matemàticament dona problemes, i no es realista :'D pero millor ser defensius que tindre un crash.
      if (r.total_participants <= 1) return false;

      // Filtrem els que son anteriors a la data de principi
      const resultDay = dayjs(r.date);
      return !resultDay.isBefore(start);
    });

    const maxParticipants = relevantResults.reduce(
      (acc, r) => Math.max(acc, r.total_participants),
      0
    );
    relevantResults.forEach((r) => {
      const resultDay = dayjs(r.date);
      const month = getMonthKey(resultDay);

      // Si hi ha pocs regatistes, les tiers son molt espaiades: una petita diferencia
      // al resultat et podria moure a altres tiers facilment. Inclús es pot donar el cas
      // que hi hagi tiers que se'ls salti. E.g. 6 participants: 1er -> 0, 2on -> 2, 3er -> 4, 4t -> 6, 5è -> 8, 6è -> 9
      // Intentem equalitzar l'efecte difuminant les que tenen pocs participants.
      // Resultion es quants tiers hi ha d'una posició a la següent.
      const resolution = 1 / (r.total_participants - 1);

      const tier =
        r.position == null
          ? 9
          : Math.min(9, (10 * (r.position - 1)) / (r.total_participants - 1));
      result[month].tiers[Math.floor(tier)].quantity++;

      // Idea es sumar 1 punt a diferents distancies de "resolution" fora del tier.
      // d'aquesta forma es degrada una mica el color en vertical, especialment per les
      // regates que tenen pocs participants, pero deixant més fort el tier al que pertany.
      const MAX_DISTANCE = maxParticipants / 2;
      for (let d = 0; d <= MAX_DISTANCE; d++) {
        const start = Math.max(0, Math.floor(tier - resolution * d));
        const end = Math.min(9, Math.floor(tier + resolution * d));
        for (let t = start; t <= end; t++) {
          result[month].tiers[t].weight++;
          result[month].total++;
        }
      }
    });

    return result;
  }, [results]);

  const includedColumns = Object.keys(data).filter(
    (month) => data[month].total > 0
  );

  if (includedColumns.length <= 1) {
    return null;
  }

  return (
    <div className="result-heatmap">
      <h3>Mapa de resultats últim any</h3>
      <table>
        <thead>
          <tr>
            <th style={{ width: 50 }}></th>
            {includedColumns.map((key) => (
              <th key={key} style={{ width: 26 }}>
                {data[key].month}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {new Array(10).fill(0).map((_, tier) => (
            <tr key={tier}>
              <td>{(tier + 1) * 10}%</td>
              {includedColumns.map((month, i) => {
                const proportion =
                  data[month].total > 0
                    ? data[month].tiers[tier].weight / data[month].total
                    : 0;

                return (
                  <td
                    key={i}
                    style={{
                      backgroundColor: `hsl(253deg 37% ${
                        100 - 82.5 * proportion
                      }%)`,
                    }}
                  ></td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

// https://aplicacions.llengua.gencat.cat/llc/AppJava/index.html?action=Principal&method=detall&input_cercar=abreviatures+mesos&numPagina=1&database=FITXES_PUB&idFont=8402&idHit=8402&tipusFont=Fitxes+de+l%2527Optimot&numeroResultat=1&databases_avansada=&categories_avansada=&clickLink=detall&titol=abreviatures+dels+mesos+de+l%2527any&tematica=&tipusCerca=cerca.normes
const months = [
  "GN",
  "FB",
  "MÇ",
  "AB",
  "MG",
  "JN",
  "JL",
  "AG",
  "ST",
  "OC",
  "NV",
  "DS",
];
function getMonthLabel(day: dayjs.Dayjs) {
  return months[day.get("month")];
}
function getMonthKey(day: dayjs.Dayjs) {
  return day.format("YYYY-MM");
}
