import { useStateObservable } from "@react-rxjs/core";
import { FC, useEffect } from "react";
import {
  Announcement,
  readToa,
  readToa$,
  toa$,
  viewToa,
} from "../../state/toa.state";
import pdfFile from "./pdf_file.png";
import { getApiURL } from "../../config";

const Toa = () => {
  const toa = useStateObservable(toa$);
  const read = useStateObservable(readToa$);

  useEffect(() => {
    viewToa();
  }, []);

  return (
    <div className="p-2 flex-1 overflow-auto">
      <h2 className="font-bold text-center">Tauler Oficial d'Anuncis</h2>
      {toa?.length ? (
        <ol className="flex flex-wrap p-2 items-center justify-center gap-2 max-w-screen-sm m-auto">
          {toa.map((announcement) => (
            <Item
              key={announcement.id}
              announcement={announcement}
              read={read.includes(announcement.path)}
            />
          ))}
        </ol>
      ) : (
        <p className="text-center text-slate-700">No hi ha anuncis publicats</p>
      )}
    </div>
  );
};

const Item: FC<{ announcement: Announcement; read: boolean }> = ({
  announcement,
  read,
}) => {
  const date = new Date(announcement.time);

  return (
    <li>
      <a
        href={getApiURL("/uploads/" + announcement.path)}
        target="_blank"
        rel="noreferrer"
        className="border rounded py-2 px-4 flex flex-col items-center relative"
        onClick={() => readToa(announcement.path)}
      >
        <img alt="pdf" src={pdfFile} className="w-10" />
        <span>{announcement.name}</span>
        <span className="text-sm text-slate-700">
          {date.getDate()} {monthToName[date.getMonth()]}, {date.getHours()}:
          {date.getMinutes().toString().padStart(2, "0")}
        </span>
        {read ? (
          <div className="w-1 h-1 bg-gray-500 rounded-full absolute left-2.5 top-2.5" />
        ) : (
          <div className="w-2 h-2 bg-red-500 rounded-full absolute left-2 top-2" />
        )}
      </a>
    </li>
  );
};

const monthToName = [
  "de Gener",
  "de Febrer",
  "de Març",
  "d'Abril",
  "de Maig",
  "de Juny",
  "de Juliol",
  "d'Agost",
  "de Septembre",
  "d'Octubre",
  "de Novembre",
  "de Desembre",
];

export default Toa;
